<template>
  <div class="sim-page-bg">
    <div class="sim-o-h__installment">
      <validation-observer ref="infoValid">
        <div class="fs-sp"></div>
        <div>
          <div class="sim-pc-header">
            <h2 class="w-100">GIỎ HÀNG & THANH TOÁN</h2>
          </div>
          <div class="sim-pc-body">
            <div class="behind-body">
              <div class="sm-text-title">Bạn đã chọn số</div>
              <div class="md-text-body">087 0838868</div>
            </div>
            <div v-if="step == 1">
              <div class="front-body">
                <div class="header__main-package">
                  <div class="main-name__main-package">ITEL100</div>
                  <div class="info-title__main-package">
                    <div class="main-price__main-package">100.000đ/tháng</div>
                    <div class="sub-price__main-package">119.000đ</div>
                  </div>
                </div>
                <div class="divide-line__main-package"></div>
                <div class="main-body__main-package">
                  <div class="main-body-content__main-package">
                    <div class="check-icon__main-package"></div>
                    <div class="content__main-package">Miễn phí gọi nội mạng dưới 20 phút tối đa 1000 đa 1000 phút Miễn
                      phí gọi nội mạng dưới 20 phút tối đa 1000 phút</div>
                  </div>
                  <div class="main-body-content__main-package">
                    <div class="check-icon__main-package"></div>
                    <div class="content__main-package">Miễn phí tối đa 1000 phút</div>
                  </div>
                </div>
                <div class="btn-ctrl__main-package">
                  <div class="selected-btn__main-package" @click="step += 1">CHỌN GÓI</div>
                </div>
              </div>
              <div class="l-front-body__content">
                <div class="header__l-front-body">
                  <div class="main-name__l-front-body">ITEL100</div>
                  <div class="info-title__l-front-body">
                    <div class="main-price__l-front-body">100.000đ/tháng</div>
                    <div class="sub-price__l-front-body">119.000đ</div>
                  </div>
                </div>
                <div class="divide-line__l-front-body"></div>
                <div class="main-body__l-front-body">
                  <div class="main-body-content__l-front-body">
                    <div class="check-icon__l-main-body-content"></div>
                    <div class="content__l-main-body-content">Miễn phí gọi nội mạng dưới 20 phút tối đa 1000 đa 1000 phút
                      Miễn phí gọi nội mạng dưới 20 phút tối đa 1000 phút</div>
                  </div>
                  <div class="main-body-content__l-front-body">
                    <div class="check-icon__l-main-body-content"></div>
                    <div class="content__l-main-body-content">Miễn phí tối đa 1000 phút</div>
                  </div>
                </div>
              </div>
              <div class="l-front-body"></div>
              <div class="r-front-body__content">
                <div class="header__l-front-body">
                  <div class="main-name__l-front-body">ITEL100</div>
                  <div class="info-title__l-front-body">
                    <div class="main-price__l-front-body">100.000đ/tháng</div>
                    <div class="sub-price__l-front-body">119.000đ</div>
                  </div>
                </div>
                <div class="divide-line__l-front-body"></div>
                <div class="main-body__l-front-body">
                  <div class="main-body-content__l-front-body">
                    <div class="check-icon__l-main-body-content"></div>
                    <div class="content__l-main-body-content">Miễn phí gọi nội mạng dưới 20 phút tối đa 1000 đa 1000 phút
                      Miễn phí gọi nội mạng dưới 20 phút tối đa 1000 phút</div>
                  </div>
                  <div class="main-body-content__l-front-body">
                    <div class="check-icon__l-main-body-content"></div>
                    <div class="content__l-main-body-content">Miễn phí tối đa 1000 phút</div>
                  </div>
                </div>
              </div>
              <div class="r-front-body"></div>
              <div class="left-arrow-btn">
                <img alt="l-arr" src="../assets/images/left-arr.png" />
              </div>
              <div class="right-arrow-btn">
                <img alt="l-arr" src="../assets/images/right-arr.png" />
              </div>
            </div>
            <div v-if="step == 2 || step == 3" class="payment-method">
              <div class="d-flex w-100">
                  <div class="l-p-info__p-method">
                    <div class="sim-img__p-method">
                      <img alt="sim-ing" src="../assets/images/sim-img.png"/>
                    </div>
                    <div class="name-package__p-method">ITEL179</div>
                  </div>
                  <div class="r-p-info__p-method">
                    <div class="price-info__p-method">
                      <div class="pack-name__p-method">Giá SIM số:</div>
                      <div class="r-package__p-method">
                        <div class="main-price__p-method">100.000đ</div>
                        <div class="sub-price__p-method">119.000đ</div>
                      </div>
                    </div>
                    <div class="price-info__p-method">
                      <div class="pack-name__p-method">Giá gói:</div>
                      <div class="r-package__p-method">
                        <div class="main-price__p-method">199.000đ/tháng</div>
                        <div class="sub-price__p-method">220.000đ</div>
                      </div>
                    </div>
                    <div class="price-info__p-method">
                      <div class="pack-name__p-method">Phụ phí eSIM:</div>
                      <div class="r-package__p-method">
                        <div class="main-price__p-method">0đ</div>
                        <div class="sub-price__p-method">40.000đ</div>
                      </div>
                    </div>
                    <div class="esim-select__p-method web">
                      <div class="esim-check__p-method">
                        <img alt="" src="../assets/images/checksquare.png"/>
                      </div>
                      <div class="esim-info__p-method">
                        <div class="using-esim__p-method">Sử dụng eSIM</div>
                        <div class="sub-esim-info__p-method">Chỉ dùng cho các dòng máy hỗ trợ eSIM</div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="esim-select__p-method mobile">
                      <div class="esim-check__p-method">
                        <img alt="" src="../assets/images/checksquare.png"/>
                      </div>
                      <div class="esim-info__p-method">
                        <div class="using-esim__p-method">Sử dụng eSIM</div>
                        <div class="sub-esim-info__p-method">Chỉ dùng cho các dòng máy hỗ trợ eSIM</div>
                      </div>
                    </div>
              <div class="divide-line__main-package"></div>
              <div class="detail-p-method" v-if="step == 2">
                <div class="title__detail-p-method">Hình thức thanh toán</div>
                <div class="sub-title__detail-p-method">Lựa chọn hình thức thanh toán, giờ đây bạn đã có thể<br/>trả góp ngay mà không cần qua thẻ tín dụng.</div>
                <div class="item__detail-p-method">
                    <div class="c-selected__detail-p-method">
                      <img alt="" src="../assets/images/circleblank.png" />
                    </div>
                    <div class="r-esim-info__p-method">
                      <div class="esim-info__p-method">
                        <div class="using-esim__p-method">Mua ngay</div>
                        <div class="thin-text__detail-p-method">Thanh toán bằng QR code/Zalo/Momo và các loại thẻ</div>
                    </div>
                      <img class="card-img__detail-p-method" alt="card" src="../assets/images/green-card.png"/>
                    </div>
                  </div>
                <div class="item__detail-p-method selected">
                  <div class="c-selected__detail-p-method">
                      <img alt="" src="../assets/images/circleblank.png" />
                    </div>
                    <div class="r-esim-info__p-method">
                      <div class="esim-info__p-method">
                        <div class="using-esim__p-method">Trả góp không qua thẻ tín dụng</div>
                        <div class="thin-text__detail-p-method">iTel góp 0%-Không cần thẻ tín dụng, QR Pay cực tiện dụng</div>
                    </div>
                      <img class="card-img__detail-p-method" alt="card" src="../assets/images/red-card.png"/>
                    </div>
                </div>
                <div class="item__detail-p-method">
                  <div class="c-selected__detail-p-method">
                      <img alt="" src="../assets/images/circleblank.png" />
                    </div>
                    <div class="r-esim-info__p-method">
                      <div class="esim-info__p-method">
                        <div class="using-esim__p-method">Trả góp qua thẻ tín dụng</div>
                        <div class="thin-text__detail-p-method">Thanh toán bằng thẻ tín dụng</div>
                      </div>
                      <img class="card-img__detail-p-method" alt="card" src="../assets/images/blue-card.png"/>
                    </div>
                </div>
                <div class="btn-ctrl__p-method">
                  <div class="back-btn__detail-p-method" @click="step -= 1">Quay lại</div>
                  <div class="continue-btn__detail-p-method" @click="step += 1">Tiếp tục</div>
                </div>
              </div>
              <div v-if="step == 3" class="installment-s">
                <div class="title__installment-s">Các gói trả góp theo tháng</div>
                <div class="l-btn__installment-s" @click="() => onPreviousPackage()">
                  <img alt="l-btn" src="../assets/images/l-arr.png"/>
                </div>
                <div class="r-btn__installment-s" @click="() => onNextPackage()">
                  <img alt="r-btn" src="../assets/images/r-arr.png"/>
                </div>
                <div class="slider-container__installment-s">
                  <div class="item-slider__installment-s"
                    v-for="item, idx in installmentPackage"
                    :key="idx"
                    :style="`left: ${163*(idx - packSliderIdx)}px`">
                    <div class="main-content__installment-s">
                      <div class="header-main-content__installment-s">
                        <div class="big-price__installment-s">{{ item.gia }}<div class="price-time__installment-s">/tháng</div></div>
                        <div class="price-time__installment-s">Kỳ hạn: {{ item.kyHan }}</div>
                      </div>
                      <div class="body-main-content__installment-s">
                        <div class="info-part__installment-s">
                          <div class="title-info-part__installment-s">Kỳ hạn:</div>
                          <div class="value-info-part__installment-s">{{ item.phanTramKyHan }}</div>
                        </div>
                        <div class="info-part__installment-s">
                          <div class="title-info-part__installment-s">Giá mua trả góp:</div>
                          <div class="value-info-part__installment-s">{{ item.giaMuaTraGop }}</div>
                        </div>
                        <div class="info-part__installment-s">
                          <div class="title-info-part__installment-s">Trả trước:</div>
                          <div class="value-info-part__installment-s">{{ item.traTruoc }}</div>
                        </div>
                        <div class="info-part__installment-s">
                          <div class="title-info-part__installment-s">Trả góp mỗi tháng:</div>
                          <div class="value-info-part__installment-s">{{ item.traGopMoiThang }}</div>
                        </div>
                        <div class="info-part__installment-s">
                          <div class="title-info-part__installment-s">Tổng tiền:</div>
                          <div class="value-info-part__installment-s">{{ item.tongTien }}</div>
                        </div>
                        <div class="info-part__installment-s">
                          <div class="title-info-part__installment-s">Chênh lệch:</div>
                          <div class="value-info-part__installment-s">{{ item.checnhLech }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="select-package__installment-s" @click="() => onSelectPackage(item)">Chọn kỳ hạn</div>
                  </div>
                </div>
                <div class="title__installment-s m-0 mb-2">Chọn gói trả góp</div>
                <div class="d-flex">
                  <div class="w-50 pr-1 cus__installment-s">
                    <Select2 :options="prepaymentAmount" placeholder="Chọn số tiền trả trước"/>
                  </div>
                  <div class="w-50 pl-1 cus__installment-s">
                    <Select2 :options="prepaymentAmount" placeholder="Chọn kỳ hạn trả góp"/>
                  </div>
                </div>
                <div v-if="selectedPackage != null" class="info-tb__installment-s">
                    <table>
                      <tr>
                        <th class="th-tb__info" colspan="2">Bảng chi tiết gói trả góp</th>
                      </tr>
                      <tr>
                        <td class="l-td">Số tháng trả góp</td>
                        <td class="r-td b-text">6 tháng</td>
                      </tr>
                      <tr>
                        <td class="l-td">Giá mua trả góp</td>
                        <td class="r-td">3.990.000đ</td>
                      </tr>
                      <tr>
                        <td class="l-td">Trả trước</td>
                        <td class="r-td">30%(3.000.000đ)</td>
                      </tr>
                      <tr>
                        <td class="l-td">Góp mỗi tháng</td>
                        <td class="r-td">1.535.940đ</td>
                      </tr>
                      <tr>
                        <td class="l-td">Tổng tiền trả góp</td>
                        <td class="r-td">4.061.820đ</td>
                      </tr>
                      <tr>
                        <td class="l-td">Chênh lệch với mua trả thẳng</td>
                        <td class="r-td">71.820đ</td>
                      </tr>
                      <tr>
                        <td class="l-td"></td>
                        <td class="r-td selected-btn__container">
                          <div class="selected-red-btn" @click="rediectToCheckout()">Chọn kỳ hạn</div>
                        </td>
                      </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="note-info" v-if="step == 1">
            <div style="color: red">(*)</div>
            <div>Hệ thống không giữ số thuê bao cho đến khi Quý Khách hoàn tất thanh toán</div>
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, min, email } from "@validations";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import useJwt from "@/auth/jwt/useJwt";
import EventBus from "@/global-components";
import Select2 from "v-select2-component";
import { OriginType, ProvinceIdConst } from '@/libs/acl/config';

import {
  BCardHeader,
  BCard,
  VBTooltip,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BPagination,
  BCardText,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormRadio,
  BFormRadioGroup,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  TabsPlugin,
  BTabs,
  BTab,
} from "bootstrap-vue";
import { WebCam } from "vue-web-cam";
import Vue from "vue";
import vSelect from "vue-select";
import StarRating from "vue-star-rating";
import Fuse from "fuse.js";
import { $ctx } from "@themeConfig";
import { v4 as uuidv4 } from "uuid";
import { formatNumber } from "@core/utils/utils";
import axios from 'axios'


let debounceHandle = null;

Vue.component("v-select", vSelect);

extend("msisdn", (value) => {
  const first4 = value.slice(0, 4);
  const first3 = value.slice(0, 3);
  if (first4 !== "8487" && first3 !== "087") {
    return false;
  } else if (
    (first4 === "8487" && value.length !== 11) ||
    (first3 === "087" && value.length !== 10)
  ) {
    return false;
  }
  return true;
});

extend("num", (value) => {
  return !/\D/.test(value);
});
extend("space", (value) => {
  return value.indexOf(" ") > 0;
});
extend("min0", (value) => {
  return value > 0;
});
extend("cccd", (value) => {
  return value && value.length >= 9 && value.length <= 12
})

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images["assets/images/ve-itel/" + item.replace("./", "")] = r(item);
  });
  return images;
}
const images = importAll(
  require.context("@/assets/images/ve-itel/", false, /.(png)$/)
);

export default {
  components: {
    BCardHeader,
    Select2,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    FeatherIcon,
    BFormCheckbox,
    BCardText,
    BPagination,
    "vue-web-cam": WebCam,
    VBTooltip,
    StarRating,
    Fuse,
    BImg,
    TabsPlugin,
    BTabs,
    BTab,
  },
  data() {
    return {
      step: 1,
      installmentPackage: [
        {
          id: 1,
          gia: "1.200.000đ",
          kyHan: "6 tháng",
          phanTramKyHan: "0%",
          giaMuaTraGop: "10.000.000đ",
          traTruoc: "30%(3.000.000đ)",
          traGopMoiThang: "1.200.000đ",
          tongTien: "12.345.000đ",
          checnhLech: "1.345.000đ",
        },
        {
          id: 2,
          gia: "1.200.000đ",
          kyHan: "6 tháng",
          phanTramKyHan: "0%",
          giaMuaTraGop: "10.000.000đ",
          traTruoc: "30%(3.000.000đ)",
          traGopMoiThang: "1.200.000đ",
          tongTien: "12.345.000đ",
          checnhLech: "1.345.000đ",
        },
        {
          id: 3,
          gia: "1.200.000đ",
          kyHan: "6 tháng",
          phanTramKyHan: "0%",
          giaMuaTraGop: "10.000.000đ",
          traTruoc: "30%(3.000.000đ)",
          traGopMoiThang: "1.200.000đ",
          tongTien: "12.345.000đ",
          checnhLech: "1.345.000đ",
        },
        {
          id: 4,
          gia: "1.200.000đ",
          kyHan: "6 tháng",
          phanTramKyHan: "0%",
          giaMuaTraGop: "10.000.000đ",
          traTruoc: "30%(3.000.000đ)",
          traGopMoiThang: "1.200.000đ",
          tongTien: "12.345.000đ",
          checnhLech: "1.345.000đ",
        }
      ],
      prepaymentAmount: [
        { id: "0", text: "6 tháng" },
        { id: "1", text: "8 tháng" },
        { id: "2", text: "12 tháng" },
        { id: "3", text: "Quỵt" }
      ],
      selectedPackage: null,
      packSliderIdx: 0
    }
  },
  watch: {
  },
  created() {
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    rediectToCheckout(){
      this.$router.push({name: "gio-hang-va-thanh-toan"})
    },
    onNextPackage() {
      if(this.packSliderIdx + 1 > this.installmentPackage.length - 1) {
        this.packSliderIdx = 0;
        return;
      }
      this.packSliderIdx++;
    },
    onPreviousPackage() {
      if(this.packSliderIdx - 1 < 0) {
        this.packSliderIdx = this.installmentPackage.length - 1;
        return;
      }
      this.packSliderIdx--;
    },
    onSelectPackage(pack) {
      this.selectedPackage = pack;
    }
  },
};
</script>
<style lang="scss">
@media screen and (max-width: 1199px) {
  .sim-o-h__installment {
    padding: 0px 20px
  }

  .b2c-pc-pack-content .b2c-pc-info,
  .b2c-pc-payment {
    margin-top: 10px !important;
  }

  .items-container {
    padding-right: 0 !important;
  }

  .huge-text {
    font-size: 2rem !important;
  }
}

@media screen and (max-width: 755px) {
  .cart .info {
    width: 100% !important;
  }

  .cart .info {
    padding-top: 10px;
  }

  .item-info {
    padding-left: 0;
    padding-top: 20px !important;
  }

  .b2c-pc-pack-content .cart {
    height: 405px !important;
  }

  .cart .image {
    width: 100% !important;
  }

  .cart .image img.image-product {
    object-fit: contain !important;
  }
}

.arrow-icon-end {
  background-image: url("../assets/images/icons/arrow-right.svg");
  background-repeat: no-repeat;
  background-size: 10px 18px;
  background-position: 95% 50%;
  padding: 11.5px !important;
}
</style>
<style lang='scss'>
.sim-page-bg {
  background: rgba(242, 242, 242, 1);
}

.sim-page-bg .custom-checkbox.custom-control {
  padding-left: 2.2rem;
}

.sim-page-bg .custom-control-label {
  line-height: 1.2;
}

.sim-pc-header {
  max-width: 130rem;
  margin-left: auto;
  margin-right: auto;
  height: 70px;
  border-bottom: 1px solid #dadada;
  width: 100%;
}

.sim-pc-header {
  display: flex;
}

.sim-pc-header h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 69px;
  color: #424242;
  width: 50%;
}

.sim-pc-body {
  min-height: 550px;
  display: flex;
  justify-content: center;
  padding: 65px 0 0;
  position: relative;
}

.note-info {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  padding: 0 0 40px 0;
}

.behind-body {
  width: 690.61px;
  height: 304px;
  background: #ED1F24;
  border-radius: 16px;
  display: flex;
  padding: 16px;
  flex-direction: column;
  text-align: center;
  z-index: 1;
  position: absolute;
}

.sm-text-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  display: flex;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
}

.md-text-body {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  display: flex;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
}

.front-body {
  z-index: 2;
  position: absolute;
  top: 175px;
  min-height: 310px;
  width: 400px;
  background: #FFFFFF;
  border: 1px solid #ED1F24;
  box-shadow: 0px 4.34154px 43.4154px rgba(0, 0, 0, 0.1);
  border-radius: 21.7077px;
  display: flex;
  flex-direction: column;
  transform: translateX(-50%);
}

.l-front-body {
  z-index: 3;
  position: absolute;
  top: 175px;
  width: 231.53px;
  height: 433.2px;
  background: linear-gradient(360deg, #F2F2F2 64.62%, rgba(255, 255, 255, 0) 89.19%);
  transform: rotate(90deg) translateY(500px);
  top: 115px;
}

.l-front-body__content {
  width: 370px;
  height: 200px;
  position: absolute;
  transform: translateX(-590px) translateY(152px);
  border-radius: 16px;
  z-index: 2;
  border: 1px solid #ED1F24;
  background: #FFFFFF;

  display: flex;
  flex-direction: column;
}

.header__l-front-body {
  padding: 10px 15px 8px;
  display: flex;
  justify-content: space-between;
}

.main-name__l-front-body {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  font-size: 25px;
  line-height: 28px;
  color: #ED1F24;
}

.main-price__l-front-body {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 17px;
  text-align: right;
  color: #424242;
}

.divide-line__l-front-body {
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
}

.main-body__l-front-body {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.main-body-content__l-front-body {
  display: flex;
  width: 100%;
  margin-bottom: 5px;
}

.sub-price__l-front-body {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  text-align: right;
  text-decoration-line: line-through;
  color: #424242;
}

.info-title__l-front-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.check-icon__l-main-body-content {
  width: 15px;
  height: 15px;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAyMyAyMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjM1MTYgMTIuMjkyOUMxMC45NjExIDEyLjY4MzQgMTAuMzI3OSAxMi42ODM0IDkuOTM3NDIgMTIuMjkyOUw5LjE0NDUzIDExLjVDOC44NjgzOSAxMS4yMjM5IDguNDIwNjcgMTEuMjIzOSA4LjE0NDUzIDExLjVDNy44NjgzOSAxMS43NzYxIDcuODY4MzkgMTIuMjIzOSA4LjE0NDUzIDEyLjVMOS45Mzc0MiAxNC4yOTI5QzEwLjMyNzkgMTQuNjgzNCAxMC45NjExIDE0LjY4MzQgMTEuMzUxNiAxNC4yOTI5TDE1LjE0NDUgMTAuNUMxNS40MjA3IDEwLjIyMzkgMTUuNDIwNyA5Ljc3NjE0IDE1LjE0NDUgOS41QzE0Ljg2ODQgOS4yMjM4NiAxNC40MjA3IDkuMjIzODYgMTQuMTQ0NSA5LjVMMTEuMzUxNiAxMi4yOTI5WiIgZmlsbD0iI0VEMUYyNCIvPgo8cGF0aCBkPSJNMjIgMTEuNUMyMiAxNy4yOTkgMTcuMjk5IDIyIDExLjUgMjJDNS43MDEwMSAyMiAxIDE3LjI5OSAxIDExLjVDMSA1LjcwMTAxIDUuNzAxMDEgMSAxMS41IDFDMTcuMjk5IDEgMjIgNS43MDEwMSAyMiAxMS41WiIgc3Ryb2tlPSIjRUQxRjI0IiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==);
}

.content__l-main-body-content {
  font-family: 'Roboto';
  font-size: 12px;
  line-height: 14px;
  padding-left: 12px;
}

.header__main-package {
  padding: 18px 22px 15px;
  display: flex;
  justify-content: space-between;
}

.main-name__main-package {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  font-size: 40px;
  line-height: 35px;
  color: #ED1F24;
}

.main-price__main-package {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  color: #424242;
}

.divide-line__main-package {
  border: 1px solid rgba(0, 0, 0, 0.093);
  width: 100%;
}

.main-body__main-package {
  display: flex;
  flex-direction: column;
  padding: 17px 22px;
}

.main-body-content__main-package {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.sub-price__main-package {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  text-align: right;
  text-decoration-line: line-through;
  color: #424242;
}

.info-title__main-package {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.check-icon__main-package {
  width: 22px;
  height: 22px;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAyMyAyMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjM1MTYgMTIuMjkyOUMxMC45NjExIDEyLjY4MzQgMTAuMzI3OSAxMi42ODM0IDkuOTM3NDIgMTIuMjkyOUw5LjE0NDUzIDExLjVDOC44NjgzOSAxMS4yMjM5IDguNDIwNjcgMTEuMjIzOSA4LjE0NDUzIDExLjVDNy44NjgzOSAxMS43NzYxIDcuODY4MzkgMTIuMjIzOSA4LjE0NDUzIDEyLjVMOS45Mzc0MiAxNC4yOTI5QzEwLjMyNzkgMTQuNjgzNCAxMC45NjExIDE0LjY4MzQgMTEuMzUxNiAxNC4yOTI5TDE1LjE0NDUgMTAuNUMxNS40MjA3IDEwLjIyMzkgMTUuNDIwNyA5Ljc3NjE0IDE1LjE0NDUgOS41QzE0Ljg2ODQgOS4yMjM4NiAxNC40MjA3IDkuMjIzODYgMTQuMTQ0NSA5LjVMMTEuMzUxNiAxMi4yOTI5WiIgZmlsbD0iI0VEMUYyNCIvPgo8cGF0aCBkPSJNMjIgMTEuNUMyMiAxNy4yOTkgMTcuMjk5IDIyIDExLjUgMjJDNS43MDEwMSAyMiAxIDE3LjI5OSAxIDExLjVDMSA1LjcwMTAxIDUuNzAxMDEgMSAxMS41IDFDMTcuMjk5IDEgMjIgNS43MDEwMSAyMiAxMS41WiIgc3Ryb2tlPSIjRUQxRjI0IiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==);
}

.content__main-package {
  font-family: 'Roboto';
  font-size: 17px;
  line-height: 19px;
  padding-left: 12px;
}

.btn-ctrl__main-package {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 20px;
}

.selected-btn__main-package {
  background: #ED1F24;
  border-radius: 26.8042px;
  width: calc(100% - 40px);
  padding: 14px 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 11px;
  text-align: center;
  color: #FFFFFF;
}

.selected-btn__main-package:hover {
  background: #ca1b1eeb;
  cursor: pointer;
}

.r-front-body {
  z-index: 3;
  position: absolute;
  top: 175px;
  width: 231.53px;
  height: 433.2px;
  background: linear-gradient(360deg, #F2F2F2 64.62%, rgba(255, 255, 255, 0) 89.19%);
  transform: rotate(-90deg) translateY(300px);
  top: 115px;
}

.r-front-body__content {
  width: 370px;
  height: 200px;
  position: absolute;
  transform: translateX(220px) translateY(152px);
  border-radius: 16px;
  z-index: 2;
  border: 1px solid #ED1F24;
  background: #FFFFFF;
}

.left-arrow-btn {
  width: 42px;
  height: 42px;
  z-index: 5;
  position: absolute;
  top: 300px;
  transform: translateX(-340px);
  cursor: pointer;
}

.left-arrow-btn>img {
  width: 100%;
  height: 100%;
}

.right-arrow-btn {
  width: 42px;
  height: 42px;
  z-index: 5;
  position: absolute;
  top: 300px;
  transform: translateX(300px);
  cursor: pointer;
}

.right-arrow-btn>img {
  width: 100%;
  height: 100%;
}

// payment method
.payment-method {
  width: 549.66px;
  min-height: 600px;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  z-index: 2;
  margin-top: 110px;
  margin-bottom: 80px;
  padding: 30px;
}

.l-p-info__p-method {
  width: 130px;
  display: flex;
  flex-direction: column;
}

.sim-img__p-method {
  width: 100%;
  box-sizing: border-box;
}

.sim-img__p-method>img {
  width: 100%;
}

.name-package__p-method {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  font-size: 26.4038px;
  line-height: 31px;
  color: #ED1F24;
}

.r-p-info__p-method {
  width: 0;
  flex-grow: 1;
  padding: 0 30px;
}

.price-info__p-method {
  display: flex;
  width: 75%;
  justify-content: space-between;
  margin-bottom: 10px;
}

.pack-name__p-method {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #424242;
}

.r-package__p-method {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.main-price__p-method {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-end;
}

.sub-price__p-method {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 10px;
  text-align: right;
  text-decoration-line: line-through;
  color: #424242;
  display: flex;
  justify-content: flex-end
}

.esim-select__p-method {
  padding: 18px 15px;
  border: 1px solid #E6E6E6;
  border-radius: 12px;
  display: flex;
  cursor: pointer;
  align-items: center;
}

.esim-select__p-method.web {
    margin: 0 0 25px;
    display: flex;
  }

.esim-select__p-method.mobile {
  display: none;
}

.esim-check__p-method {
  width: 27px;
  height: 27px;
}

.esim-check__p-method>img {
  width: 100%;
  height: 100%;
}

.esim-info__p-method {
  display: flex;
  width: 0;
  flex-grow: 1;
  flex-direction: column;
  padding-left: 15px;
}

.using-esim__p-method {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: left;
  color: #333333;
}

.sub-esim-info__p-method {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #424242;
}

.detail-p-method {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
}

.title__detail-p-method {
  display: flex;
  justify-content: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #4B4B4B;
  margin-bottom: 10px;
}

.sub-title__detail-p-method {
  display: flex;
  justify-content: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #4B4B4B;
  margin-bottom: 20px;
}

.item__detail-p-method {
  margin: 10px 0;
  background: #FFFFFF;
  border: 1px solid #DADADA;
  border-radius: 10px;
  padding: 14px 25px;
  display: flex;
}

.item__detail-p-method.selected {
  background: #FFF7F7;
  border: 1px solid #ED1F24;
}

.c-selected__detail-p-method {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.c-selected__detail-p-method>img {
  width: 17px;
  height: 17px;
}

.thin-text__detail-p-method {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 12px;
  text-align: left;
  display: flex;
  color: #4B4B4B;
}

.r-esim-info__p-method {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  width: 0;
  align-items: center;
}

.card-img__detail-p-method {
  height: 30px;
  width: 48px;
  margin-left: 18px;
}

.btn-ctrl__p-method {
  margin: 30px 0 0;
  display: flex;
  justify-content: center;
}

.back-btn__detail-p-method {
  background: #FFFFFF;
  border: 1.12253px solid #ED1F24;
  border-radius: 24.0355px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 182px;
  height: 45px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14.9671px;
  line-height: 16px;
  text-align: center;
  color: #ED1F24;
  margin: 0 5px;
  cursor: pointer;
}

.back-btn__detail-p-method:hover {
  background: #f3f3f3;
}

.continue-btn__detail-p-method {
  background: #ED1F24;
  border: 1.12253px solid #ED1F24;
  border-radius: 24.0355px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 182px;
  height: 45px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14.9671px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
  margin: 0 5px;
  cursor: pointer;
}

.continue-btn__detail-p-method:hover {
  background: #d01b1e;
}

// installment shopping
.installment-s {
  display: flex;
  flex-direction: column;
  position: relative;
}

.title__installment-s {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: #4B4B4B;
  margin: 24px 0 10px;
}

.slider-container__installment-s {
  width: 100%;
  height: 280px;
  position: relative;
  overflow: hidden;
}

.item-slider__installment-s {
  width: 165px;
  padding: 0 6px;
  height: calc(100% - 20px);
  position: absolute;
  top: 10px;
  transition: left 0.2s;
}

.main-content__installment-s {
  height: calc(100% - 25px);
  width: 100%;
  background: #FFFFFF;
  border: 0.577458px solid #D9D9D9;
  box-shadow: 2px 2px 11px 0px rgb(209 206 206 / 38%);
  border-radius: 17.8032px;
}

.header-main-content__installment-s {
  height: 80px;
  width: 100%;
  background-image: url('../assets/images/header-package.png');
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 15px 10px 0;
}

.big-price__installment-s {
  display: flex;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  justify-content: center;
  text-align: center;
  align-items: flex-end;
}

.price-time__installment-s {
  display: flex;
  justify-content: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  color: #FFFFFF;
  align-items: flex-end;
}

.body-main-content__installment-s {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5px;
  padding: 0 10px;
}

.info-part__installment-s {
  width: 100%;
  display: flex;
  margin-bottom: 6px;
}

.title-info-part__installment-s {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 10px;
  color: #797979;
  width: 50%;
  text-align: left;
}

.value-info-part__installment-s {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 11px;
  color: #797979;
  width: 50%;
}

.select-package__installment-s {
  position: absolute;
  bottom: 15px;
  left: 35px;
  height: 25px;
  width: 95px;
  background: red;
  z-index: 5;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
}

.select-package__installment-s:hover {
  background: rgb(237, 1, 1);
}

.cus__installment-s .select2-selection__placeholder {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  color: #808080;
  height: 100%;
  display: flex;
  align-items: center;
}

.cus__installment-s .select2-selection__rendered {
  align-items: center;
  display: flex !important;
  height: 100%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 13px;
  color: #808080 !important;
}

.cus__installment-s .select2-container .select2-selection--single {
  height: 40px !important;
  padding: 0 10px !important;
}

.cus__installment-s .select2-container .select2-selection--single::before {
  background-image: url('../assets/images/down-arr.png')
}

.l-btn__installment-s {
  width: 45px;
  height: 45px;
  position: absolute;
  z-index: 5;
  left: 0;
  top: 175px;
  transform: translateX(-50%);
  cursor: pointer;
}

.l-btn__installment-s>img {
  width: 100%;
  height: 100%;
}

.r-btn__installment-s {
  width: 45px;
  height: 45px;
  position: absolute;
  z-index: 5;
  right: 0;
  top: 175px;
  transform: translateX(50%);
  cursor: pointer;
}

.r-btn__installment-s>img {
  width: 100%;
  height: 100%;
}

.info-tb__installment-s {
  margin-top: 20px;
  border-radius: 15px;
  border: 1px solid #DFDFDF;
  width: 100%;
  overflow: hidden;
  border-bottom: none;
}

.info-tb__installment-s>table {
  border-collapse: collapse;
  width: 100%;
}

.info-tb__installment-s th, .info-tb__installment-s td {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 15px 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
}

.info-tb__installment-s th {
  border-top: none;
  border-left: none;
  border-right: none;
  background: #F0F0F0;
}

.info-tb__installment-s td {
  width: 50%;
}

.info-tb__installment-s td.l-td {
  border-left: none;
  border-top: none;
  border-right: none;
}

.info-tb__installment-s td.r-td {
  border-top: none;
  border-right: none;
}

.b-text {
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 13.2256px !important;
  line-height: 16px !important;
  color: #353535 !important;
}

.selected-btn__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}

.selected-red-btn {
  height: 25px;
  width: 95px;
  background: red;
  z-index: 5;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
}

.selected-red-btn:hover {
  background: rgb(237, 1, 1);
}

@media screen and (max-width: 755px) {
  .sim-o-h__installment {
    padding: 0;
  }

  .sim-pc-header {
    max-width: 130rem;
    margin-left: auto;
    margin-right: auto;
    height: 70px;
    border-bottom: 1px solid #dadada;
    width: 100%;
  }

  .sim-pc-header {
    display: flex;
  }

  .sim-pc-header h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 69px;
    color: #424242;
    width: 50%;
  }

  .behind-body {
    width: 690.61px;
    height: 304px;
    background: #ED1F24;
    border-radius: 16px;
    display: flex;
    padding: 16px;
    flex-direction: column;
    text-align: center;
    z-index: 1;
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .sm-text-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 36px;
    display: flex;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
  }

  .md-text-body {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    display: flex;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
  }

  .front-body {
    z-index: 2;
    position: absolute;
    top: 175px;
    min-height: 310px;
    width: calc(100% - 40px);
    background: #FFFFFF;
    border: 1px solid #ED1F24;
    box-shadow: 0px 4.34154px 43.4154px rgba(0, 0, 0, 0.1);
    border-radius: 21.7077px;
    display: flex;
    flex-direction: column;
  }

  .l-front-body {
    display: none;
  }

  .l-front-body__content {
    display: none;
  }

  .check-icon__l-main-body-content {
    width: 15px;
    height: 15px;
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAyMyAyMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjM1MTYgMTIuMjkyOUMxMC45NjExIDEyLjY4MzQgMTAuMzI3OSAxMi42ODM0IDkuOTM3NDIgMTIuMjkyOUw5LjE0NDUzIDExLjVDOC44NjgzOSAxMS4yMjM5IDguNDIwNjcgMTEuMjIzOSA4LjE0NDUzIDExLjVDNy44NjgzOSAxMS43NzYxIDcuODY4MzkgMTIuMjIzOSA4LjE0NDUzIDEyLjVMOS45Mzc0MiAxNC4yOTI5QzEwLjMyNzkgMTQuNjgzNCAxMC45NjExIDE0LjY4MzQgMTEuMzUxNiAxNC4yOTI5TDE1LjE0NDUgMTAuNUMxNS40MjA3IDEwLjIyMzkgMTUuNDIwNyA5Ljc3NjE0IDE1LjE0NDUgOS41QzE0Ljg2ODQgOS4yMjM4NiAxNC40MjA3IDkuMjIzODYgMTQuMTQ0NSA5LjVMMTEuMzUxNiAxMi4yOTI5WiIgZmlsbD0iI0VEMUYyNCIvPgo8cGF0aCBkPSJNMjIgMTEuNUMyMiAxNy4yOTkgMTcuMjk5IDIyIDExLjUgMjJDNS43MDEwMSAyMiAxIDE3LjI5OSAxIDExLjVDMSA1LjcwMTAxIDUuNzAxMDEgMSAxMS41IDFDMTcuMjk5IDEgMjIgNS43MDEwMSAyMiAxMS41WiIgc3Ryb2tlPSIjRUQxRjI0IiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==);
  }

  .content__l-main-body-content {
    font-family: 'Roboto';
    font-size: 12px;
    line-height: 14px;
    padding-left: 12px;
  }

  .header__main-package {
    padding: 18px 22px 15px;
    display: flex;
    justify-content: space-between;
  }

  .main-name__main-package {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 900;
    font-size: 32px;
    line-height: 35px;
    color: #ED1F24;
  }

  .main-price__main-package {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    text-align: right;
    color: #424242;
  }

  .main-body__main-package {
    display: flex;
    flex-direction: column;
    padding: 17px 22px;
  }

  .main-body-content__main-package {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
  }

  .sub-price__main-package {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    text-align: right;
    text-decoration-line: line-through;
    color: #424242;
  }

  .info-title__main-package {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .check-icon__main-package {
    width: 22px;
    height: 22px;
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAyMyAyMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjM1MTYgMTIuMjkyOUMxMC45NjExIDEyLjY4MzQgMTAuMzI3OSAxMi42ODM0IDkuOTM3NDIgMTIuMjkyOUw5LjE0NDUzIDExLjVDOC44NjgzOSAxMS4yMjM5IDguNDIwNjcgMTEuMjIzOSA4LjE0NDUzIDExLjVDNy44NjgzOSAxMS43NzYxIDcuODY4MzkgMTIuMjIzOSA4LjE0NDUzIDEyLjVMOS45Mzc0MiAxNC4yOTI5QzEwLjMyNzkgMTQuNjgzNCAxMC45NjExIDE0LjY4MzQgMTEuMzUxNiAxNC4yOTI5TDE1LjE0NDUgMTAuNUMxNS40MjA3IDEwLjIyMzkgMTUuNDIwNyA5Ljc3NjE0IDE1LjE0NDUgOS41QzE0Ljg2ODQgOS4yMjM4NiAxNC40MjA3IDkuMjIzODYgMTQuMTQ0NSA5LjVMMTEuMzUxNiAxMi4yOTI5WiIgZmlsbD0iI0VEMUYyNCIvPgo8cGF0aCBkPSJNMjIgMTEuNUMyMiAxNy4yOTkgMTcuMjk5IDIyIDExLjUgMjJDNS43MDEwMSAyMiAxIDE3LjI5OSAxIDExLjVDMSA1LjcwMTAxIDUuNzAxMDEgMSAxMS41IDFDMTcuMjk5IDEgMjIgNS43MDEwMSAyMiAxMS41WiIgc3Ryb2tlPSIjRUQxRjI0IiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==);
  }

  .content__main-package {
    font-family: 'Roboto';
    font-size: 17px;
    line-height: 19px;
    padding-left: 12px;
  }

  .btn-ctrl__main-package {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 20px;
  }

  .selected-btn__main-package {
    background: #ED1F24;
    border-radius: 26.8042px;
    width: calc(100% - 40px);
    padding: 14px 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 11px;
    text-align: center;
    color: #FFFFFF;
  }

  .selected-btn__main-package:hover {
    background: #ca1b1eeb;
    cursor: pointer;
  }

  .r-front-body {
    display: none;
  }

  .r-front-body__content {
    display: none;
  }

  .left-arrow-btn {
    width: 42px;
    height: 42px;
    z-index: 5;
    position: absolute;
    left: 0;
    transform: none;
    cursor: pointer;
  }

  .left-arrow-btn>img {
    width: 100%;
    height: 100%;
  }

  .right-arrow-btn {
    width: 42px;
    height: 42px;
    z-index: 5;
    position: absolute;
    right: 0;
    transform: none;
    cursor: pointer;
  }

  .right-arrow-btn>img {
    width: 100%;
    height: 100%;
  }

  .note-info {
    box-sizing: border-box;
    padding-right: 15px;
    padding-left: 15px;
  }

  // payment-method
  .payment-method {
    width: 100%;
    padding: 30px 20px;
  }
  .l-p-info__p-method {
    width: 100px;
  }
  .r-p-info__p-method {
    padding-right: 0;
  }
  .price-info__p-method {
    width: 100%;
  }
  .esim-select__p-method.web {
    display: none;
  }
  .esim-select__p-method.mobile {
    margin: 15px 0 25px;
    display: flex;
  }
  .detail-p-method {
    padding: 30px 10px;
    display: flex;
    flex-direction: column;
  }
  .item__detail-p-method {
    margin: 10px 0;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 10px;
    padding: 8px 15px;
    display: flex;
  }
  .using-esim__p-method {
    font-size: 13px;
  }
  .thin-text__detail-p-method {
    font-size: 10px;
  }
}
</style>
